import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '@core/services/env.service';
import { TranslocoService } from '@jsverse/transloco';
import { IProcessingBatchService } from '@shared/interfaces/IbatchService.interface';
import { Papa } from 'ngx-papaparse';
import { catchError, EMPTY, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { ProcessingBatchType } from '../components/steps/upload-parking-tickets/types/processing-batch.type';
import { PaginatedDto, ProcessingBatchParkingTicketListResponseDto } from '../models/pagination.model';
import { ParkingTicketAddressModel } from '../models/parking-ticket/parking-ticket-address.model';
import { ParkingTicketConfirmation } from '../models/parking-ticket/parking-ticket-confirmation';
import { ParkingTicket } from '../models/parking-ticket/parking-ticket.model';
import { ProcessingBatch } from '../models/parking-ticket/processing-batch.model';
import { ParkingTicketExceptionCode } from '../types/exceptions/parking-ticket-exception';
import { StatusEventsService } from './status-events.service';
import { ToastService } from './toast.service';
import { ServiceInstanceId } from '@shared/services/service-instance-id';
import { ProcessingBatchPaymentInformationSSEMessage, SseMessage } from '@shared/models/sse-message.model';
import { CsvExport } from '@shared/models/processing-batch/csv-export.model';
import { DateUtils } from '@shared/utils/date.utils';
import { VehicleAssignmentDto } from '@shared/models/vehicle-assignment/vehicle-assignment.model';
import { ParkingTicketPaymentStatus } from '@shared/types/parking-ticket-payment.status';

@Injectable({
  providedIn: 'root',
})
export class ProcessingBatchHttpService extends ServiceInstanceId implements IProcessingBatchService {
  private processingBatches = new ReplaySubject<PaginatedDto<ProcessingBatch>>(1);
  public processingBatches$ = this.processingBatches.asObservable();

  private parkingTickets = new ReplaySubject<ProcessingBatchParkingTicketListResponseDto>(1);
  public parkingTickets$ = this.parkingTickets.asObservable();

  public selectedProcessingBatch = new ReplaySubject<ProcessingBatch>(1);
  public selectedProcessingBatch$ = this.selectedProcessingBatch.asObservable();

  private eventSource$!: Observable<SseMessage<ProcessingBatchPaymentInformationSSEMessage>>;

  private datesWithBatches = new ReplaySubject<string[]>(1);
  public datesWithBatches$ = this.datesWithBatches.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvService,
    private readonly statusEventService: StatusEventsService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
    private readonly router: Router,
  ) {
    super();
  }

  public createParkingTickets(paths: string[], type: ProcessingBatchType): Observable<ProcessingBatch> {
    return this.http.post<ProcessingBatch>(`${this.env.baseUrl}/processing-batch`, { paths, type });
  }

  public getAllPaginatedProcessingBatches(page: number, size: number): void {
    this.http
      .get<PaginatedDto<ProcessingBatch>>(`${this.env.baseUrl}/processing-batch/`, {
        params: { page, size },
      })
      .pipe(
        map((response: PaginatedDto<ProcessingBatch>) => {
          this.getMonthsWithBatches();
          this.processingBatches.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getPaginatedParkingTicketsForBatch(batchId: number, page: number, size: number): void {
    this.http
      .get<ProcessingBatchParkingTicketListResponseDto>(
        `${this.env.baseUrl}/processing-batch/${batchId}/parking-tickets`,
        {
          params: { page, size },
        },
      )
      .pipe(
        map((response: ProcessingBatchParkingTicketListResponseDto) => {
          this.parkingTickets.next(response);
        }),
        catchError((error: HttpErrorResponse, _: any, errorMessage = '') => {
          if (error.status === 404) {
            void this.router.navigate(['/', 'app', 'current-batches']);
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public getProcessingBatchById(batchId: number): void {
    this.http
      .get<ProcessingBatch>(`${this.env.baseUrl}/processing-batch/${batchId}`)
      .pipe(
        catchError((error: HttpErrorResponse, _: any, errorMessage = '') => {
          if (error.status === 404) {
            void this.router.navigate(['/', 'app', 'current-batches']);
          }
          return EMPTY;
        }),
      )
      .subscribe((response: ProcessingBatch) => {
        this.selectedProcessingBatch.next(response);
      });
  }

  public updateParkingTicket(
    batchId: number,
    parkingTicketId: number,
    data: ParkingTicketConfirmation,
  ): Observable<ParkingTicket> {
    return this.http
      .patch<ParkingTicket>(`${this.env.baseUrl}/processing-batch/${batchId}/${parkingTicketId}`, data)
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse, _: any, errorMessage = ''): Observable<never> {
    console.error(error);
    switch (error?.error?.errorCode) {
      case ParkingTicketExceptionCode.INVALID_PAYMENT_NOTICE: {
        this.toastService.error(this.translateService.translate('app.errors.invalid_payment_notice'));
        break;
      }
      default: {
        this.statusEventService.setHttpStatus(false);
        this.toastService.error(this.translateService.translate('app.errors.generic_network_error'));
        return EMPTY;
      }
    }
    return EMPTY;
  }

  public createCheckoutSession(id: number): Observable<{ clientSecret: string }> {
    return this.http.get<{ clientSecret: string }>(`${this.env.baseUrl}/processing-batch/${id}/client-secret`);
  }

  public deleteParkingTicket(batchId: number, parkingTicketId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.env.baseUrl}/processing-batch/${batchId}/${parkingTicketId}`);
  }

  public aojojo(batchId: number): void {
    this.http.get<void>(`${this.env.baseUrl}/processing-batch/${batchId}/aojojo`).subscribe();
  }

  private getPaymentDetails(batchId: number): void {
    this.http.post<void>(`${this.env.baseUrl}/processing-batch/${batchId}/payment-details`, {}).subscribe();
  }

  public subscribeToPaymentDetails(
    batchUUID: string,
    batchId: number,
  ): Observable<SseMessage<ProcessingBatchPaymentInformationSSEMessage>> {
    if (!this.eventSource$) {
      this.eventSource$ = new Observable<SseMessage<ProcessingBatchPaymentInformationSSEMessage>>((observer) => {
        const eventSource = new EventSource(
          `${this.env.baseUrl}/processing-batch/payment-details?batchUUID=${batchUUID}`,
        );

        eventSource.onmessage = (event) => {
          if (event.data === 'endOfStream') {
            eventSource.close();
            observer.complete();
            return;
          }

          if (event.data === 'unleashTheKraken') {
            this.getPaymentDetails(batchId);
            return;
          }

          const data = event.data;

          if (data.error) {
            observer.error(data.error);
            return;
          }

          observer.next(JSON.parse(data));
        };

        eventSource.onerror = () => {
          eventSource.close();
          observer.error('EventSource failed');
        };

        return () => {
          eventSource.close();
        };
      }).pipe(
        // Utilisation de shareReplay(1) pour partager le flux et réutiliser la connexion
        shareReplay(1),
      );
    }
    return this.eventSource$;
  }

  public getMonthsWithBatches(): void {
    this.http
      .get<string[]>(`${this.env.baseUrl}/processing-batch/months`)
      .pipe(
        map((response: string[]) => {
          this.datesWithBatches.next(response);
        }),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  public getSpecificMonth(date: string): Observable<ProcessingBatch[]> {
    return this.http
      .get<ProcessingBatch[]>(`${this.env.baseUrl}/processing-batch/months/${date}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getInvoice(batchId: number): Observable<{ invoiceUrl: string }> {
    return this.http
      .get<{ invoiceUrl: string }>(`${this.env.baseUrl}/processing-batch/${batchId}/invoice`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getDuplicatedDrivers(batchId: number, parkingTicketId: number): Observable<VehicleAssignmentDto[]> {
    return this.http
      .get<
        VehicleAssignmentDto[]
      >(`${this.env.baseUrl}/processing-batch/${batchId}/parking-tickets/${parkingTicketId}/drivers`)
      .pipe(catchError(this.handleError.bind(this)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProcessingBatchUtilsService {
  public defaultFieldsWithMappings = [
    'amount',
    'offenceDate',
    'collectivityName',
    'address',
    'paymentNotice',
    'paymentKey',
    'paidToStateDate',
    'amountPaidToState',
    'paymentStatus',
  ];
  public defaultFieldsWithoutMappings = [
    ...this.defaultFieldsWithMappings,
    'licencePlate',
    'rentalReference',
    'driverEmail',
    'driverReference',
  ];

  public dateFields = ['offenceDate', 'paidToStateDate', 'startDate', 'endDate'];

  constructor(private readonly parse: Papa) {}

  public exportBatchAsCsv(fileName: string, parkingTickets: ParkingTicket[], exportData: CsvExport): void {
    const { ptSelectedFields: defaultFields, additionalSelectedFields } = exportData;
    const additionalFieldsColumns: string[] = [];
    const convertedArray = parkingTickets.map((ticket) => {
      const convertedObject: any = {};
      defaultFields.forEach((field) => {
        if (field === 'address') {
          convertedObject[field] = this.constructAddress(ticket[field]);
        } else if (field === 'amount') {
          convertedObject[field] = ticket.minoredAmount ?? ticket.amount;
        } else if (field === 'paymentStatus') {
          convertedObject[field] =
            ticket.paymentStatus === ParkingTicketPaymentStatus.ALREADY_PAID ? 'employee' : 'FPSNavigator';
        } else {
          convertedObject[field] = ticket[field as keyof ParkingTicket];
        }
        if (this.dateFields.includes(field) && ticket[field]) {
          try {
            convertedObject[field] = DateUtils.formatDateToCustomFormatAndTimezone(
              ticket[field] as string,
              DateUtils.dateTimeFormatUser,
              DateUtils.timezoneParis,
            );
          } catch (error) {
            convertedObject[field] = ticket[field];
          }
        }
      });
      if (additionalSelectedFields) {
        additionalSelectedFields.forEach((field) => {
          if (this.dateFields.includes(field) && ticket.vehicleAssignment[field]) {
            try {
              convertedObject[exportData.headerMapping[field]] = DateUtils.formatDateToCustomFormatAndTimezone(
                ticket.vehicleAssignment[field],
                DateUtils.dateTimeFormatUser,
                DateUtils.timezoneParis,
              );
            } catch (error) {
              convertedObject[exportData.headerMapping[field]] = ticket.vehicleAssignment[field];
            }
          } else {
            convertedObject[exportData.headerMapping[field]] = ticket.vehicleAssignment[field];
          }
        });
      }
      return convertedObject;
    });

    if (additionalSelectedFields) {
      additionalFieldsColumns.push(...additionalSelectedFields.map((field) => exportData.headerMapping[field]));
    }

    this.downloadCsv(
      fileName,
      this.parse.unparse(convertedArray, {
        columns: [...additionalFieldsColumns, ...defaultFields],
        delimiter: ';',
        header: true,
        skipEmptyLines: 'greedy',
      }),
    );
  }

  private downloadCsv(fileName: string, csvContent: string) {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    a.href = url;
    a.download = fileName + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public constructAddress(parkingTicket: ParkingTicketAddressModel): string {
    if (parkingTicket?.line1 && parkingTicket?.postCodeCity) {
      return `${parkingTicket.line1} ${parkingTicket.postCodeCity}`;
    } else {
      return '-';
    }
  }
}
